<template>
  <div>
    <slot></slot>
  </div>
</template>

<script setup></script>

<style>
body {
  background-color: rgb(2 6 23);
}
</style>
